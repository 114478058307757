import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Divider, FormControl, Button, MenuItem, InputLabel } from "@material-ui/core";
import { TextField, Checkbox, Select } from "formik-material-ui";
import { Field, Form } from "formik";
import landCodes from "../landCodes";
import { Trans, useTranslation } from "react-i18next";
import NroTotals from "./NroTotals";

function OrderNroPaymentForm({ formikProps, classes, setPersonPrijsTotal, pricelist, totalPersons }) {
	const { t } = useTranslation();

	const [personPrijs, setPersonPrijs] = useState(0.0);
	const [discountPrice, setDiscountprice] = useState({});
	const [aanvraagPrijs, setAanvraagPrijs] = useState(0.0);
	const [discount, setDiscount] = useState(null);

	const { values } = formikProps;

	return (
		<React.Fragment>
			<Form>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Typography variant="subtitle1" align="center">
							De levertijd is minimaal een week. Wanneer de bestelling klaarstaat, ontvang je een e-mail met een instructie hoe je die kunt
							downloaden.
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Divider />
					</Grid>
					<Grid item xs={12} sm={6}>
						<Typography variant="h6" gutterBottom>
							{/* {t('personalInformation')} */}
							Gegevens van de aanvrager
						</Typography>
					</Grid>
					<Grid item xs={12} sm={6}>
						<Button
							fullWidth
							onClick={() => {
								formikProps.setFieldValue("showStepTwo", false);
							}}
							variant="contained"
							color="primary"
						>
							Terug
						</Button>
					</Grid>
					<Grid item xs={12} sm={6}>
						<Field component={TextField} required name="firstName" label={t("firstName")} fullWidth autoComplete="given-name" />
					</Grid>
					<Grid item xs={12} sm={6}>
						<Field component={TextField} values={values.initials} name="initials" label={t("initials")} fullWidth />
					</Grid>
					<Grid item xs={12} sm={6}>
						<Field component={TextField} name="lastNamePrefix" label={"Voorvoegsel"} fullWidth />
					</Grid>
					<Grid item xs={12} sm={6}>
						<Field component={TextField} required name="lastName" label={t("lastName")} fullWidth autoComplete="family-name" />
					</Grid>
					<Grid item xs={12}>
						<Divider />
					</Grid>
					<Grid item xs={12}>
						<Typography variant="h6" gutterBottom>
							{t("contactDetails")}
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Field component={TextField} required name="street" label={t("street")} fullWidth autoComplete="street-address" />
					</Grid>
					<Grid item xs={12} sm={6}>
						<Field component={TextField} required name="houseNumber" label={t("houseNumber")} fullWidth />
					</Grid>
					<Grid item xs={12} sm={6}>
						<Field component={TextField} name="addition" label={t("houseNumberAddition")} fullWidth />
					</Grid>
					<Grid item xs={12} sm={6}>
						<Field component={TextField} required name="zipCode" label={t("zipcode")} fullWidth autoComplete="postal-code" />
					</Grid>
					<Grid item xs={12} sm={6}>
						<Field component={TextField} required name="city" label={t("city")} fullWidth autoComplete="address-level2" />
					</Grid>
					<Grid item xs={12}>
						<FormControl fullWidth required>
							<InputLabel htmlFor="country">{t("country")}</InputLabel>
							<Field component={Select} name="country" label={t("country")} fullWidth autoComplete="country">
								<MenuItem value="NL">Nederland</MenuItem>
								<MenuItem value="B">België</MenuItem>
								<MenuItem value="D">Duitsland</MenuItem>
								<MenuItem value="F">Frankrijk</MenuItem>
								<MenuItem value="GB">Verenigd Koninkrijk</MenuItem>
								<MenuItem value="AUS">Australië</MenuItem>
								<MenuItem value="CDN">Canada</MenuItem>
								<MenuItem value="USA">Verenigde Staten</MenuItem>
								<MenuItem value="" disabled>
									--
								</MenuItem>
								{landCodes.map((l) => (
									<MenuItem value={l.Code_land}>{l.Land}</MenuItem>
								))}
							</Field>
						</FormControl>
					</Grid>
					<Grid item xs={12}>
						<Field component={TextField} name="phone" label={t("phone")} fullWidth autoComplete="tel" />
					</Grid>
					<Grid item xs={12}>
						<Divider />
					</Grid>
					<Grid item xs={12}>
						<Typography variant="h6">{t("terms")}</Typography>
						<FormControlLabel
							control={<Field component={Checkbox} type="checkbox" name="terms" required />}
							label={
								<Trans>
									<span>Ik ga akkoord met de </span>
									<a href="https://cbg.nl/leveringsvoorwaardennro/" target="_blank" rel="noopener noreferrer">
										Leveringsvoorwaarden NRO.
									</a>
									<span className="MuiFormLabel-asterisk">*</span>
								</Trans>
							}
						/>
					</Grid>
				</Grid>

				<NroTotals
					classes={classes}
					personValues={values}
					personPrijs={personPrijs}
					setPersonPrijs={setPersonPrijs}
					//  personPrijsTotaal={personPrijsTotaal}
					setPersonPrijsTotal={setPersonPrijsTotal}
					discount={discount}
					setDiscount={setDiscount}
					discountPrice={discountPrice}
					setDiscountprice={setDiscountprice}
					aanvraagPrijs={aanvraagPrijs}
					setAanvraagPrijs={setAanvraagPrijs}
					pricelist={pricelist}
					totalPersons={totalPersons}
					{...formikProps}
				/>
				<div style={{ display: "flex", justifyContent: "flex-end" }}>
					<Button fullWidth type="submit" variant="contained" color="primary">
						{t("pay")}
					</Button>
				</div>
			</Form>
		</React.Fragment>
	);
}

export default OrderNroPaymentForm;
