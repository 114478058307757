import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import logo from "../images/logo-cbg-corporate.svg";
import Button from "@material-ui/core/Button";
import { Auth, API } from 'aws-amplify';
import { Formik } from 'formik'
import PersonForm from "./PersonForm";
import { Trans, useTranslation } from "react-i18next";
import { ErrorDialog } from "./ErrorDialog";
import * as Yup from "yup";

const useStyles = makeStyles(theme => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(550 + theme.spacing(3 * 2))]: {
      width: 550,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    marginTop: theme.spacing(3),
  },
}));

function CustomSignUp(props) {
  const { authState, onStateChange, onAuthEvent } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({});
  const [open, setOpen] = useState(false);

  function errorDialog(err) {
    setError(err);
    setOpen(true);
  }

  if (authState !== 'signUp') return null;

  function errorMessage(err) {
    if (typeof err === 'string') { return err; }
    return err.message? err.message : JSON.stringify(err);
  }

  async function signUp(values, actions) {
    const {password, ...without_pw} = values;
    const {username, firstName, lastName} = values;
    const signup_info = {
      username, password, attributes: {given_name: firstName, family_name: lastName }
    };
    try {
      const apiName = 'oAuth';
      const path = '/register/saveSignUpFormData';

      await API.post(apiName, path, { body: without_pw});
      await Auth.signUp(signup_info);
      if (onStateChange) {
        onStateChange('confirmSignUp', username);
      }
    } catch(err) {
      console.log(err);
      if(err.code === 'UsernameExistsException' || err.code === 'UserLambdaValidationException') {
        onStateChange('confirmSignUp', {username, resend: true});
      } else {
        errorDialog(err);
        setLoading(false);
        onAuthEvent(authState, {type: 'error', data: errorMessage(err)});
      }
    } finally {
      actions.setSubmitting(false);
    }
  }

  function signIn() {
    if (onStateChange) {
      onStateChange('signIn');
    }
  }

  return (
      <main className={classes.main}>
        <CssBaseline/>
        <Paper className={classes.paper}>
          <Typography variant="h1">
            <img src={logo} alt="CBG"/>
          </Typography>

          <Typography component="h2" variant="h4">
            {t('signup')}
          </Typography>
          <Typography variant="subtitle1">
            <Trans i18nKey={"signup_subtitle"}>
              Nieuwe gebruiker? <a href={t('signup_info_link')} target="_blank" rel="noopener noreferrer">voordelen</a>.
            </Trans>
          </Typography>
          <Formik
              onSubmit={(values,actions) => {signUp(values, actions)}}
              initialValues={{
                username: '',
                password: '',
                firstName: '',
                initials: '',
                lastNamePrefix: '',
                lastName: '',
                gender: '',
                country: '',
                zipCode: '',
                houseNumber: '',
                addition: '',
                street: '',
                city: '',
                phone: '',
                newsCbg: false,
              }}
              validationSchema={Yup.object({
                firstName: Yup.string().required(t("First name is required")),
                initials: Yup.string().max(15),
                lastName: Yup.string().required(t("Last name is required")),
              })}

          >
            { (props) => PersonForm({ ...props, formVariant: 'signUp' }) }
          </Formik>
        </Paper>
        <Paper className={classes.paper}>
          <Typography component="h2" variant="h4">
            {t('signin')}
          </Typography>
          <Typography variant="subtitle1" style={{marginBottom: '1em'}}>
            {t('signin_subtitle')}
          </Typography>
          <Button
              type="button"
              fullWidth
              variant="contained"
              color="secondary"
              disabled={loading}
              onClick={(e) => {
                signIn(e)
              }}
          >
            {t('backToSignin')}
          </Button>
        </Paper>
        <ErrorDialog open={open} error={error} onClose={() => setOpen(false)} />
      </main>
  );
}

export default CustomSignUp;
