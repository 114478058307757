import React, { useEffect, useMemo } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { Divider } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import _ from "lodash";

function calcActivePersons(totalPersons) {
	let entries = Object.entries(totalPersons);

	let group = _.groupBy(entries, function (b) {
		return b[0].split("-")[b[0].split("-").length - 1];
	});

	let persons = Object.keys(group).map((personIndex) => {
		let valuesArray = group[personIndex];
		let person = {
			id: personIndex,
		};
		valuesArray.forEach((vArray) => {
			person[vArray[0].replace(`-${personIndex}`, "")] = vArray[1];
		});
		return person;
	});

	persons = persons.filter((person) => person.active);

	return persons.length;
}

function calculatePriceData(pricelist, discount, values, personValues, setPersonPrijsTotal, setAanvraagPrijs, totalPersons) {
	// altijd nul maar misschien in de toekomst nog nodig?
	let discountPrice = { discountType: ``, value: 0.0 };
	let personPrijs = 0;
	let personPrijsTotaal = 0;
	let aanvraagPrijs = 0;

	if (pricelist.length === 0) {
		return { discountPrice: discountPrice, personPrijs, personPrijsTotaal };
	}

	let aantalPersonen = calcActivePersons(totalPersons);

	let eindprijs = aantalPersonen * pricelist[0].Verkoopprijs;
	aanvraagPrijs = pricelist[0].Verkoopprijs;
	setAanvraagPrijs(pricelist[0].Verkoopprijs);
	personPrijs = aantalPersonen * pricelist[0].Verkoopprijs;
	personPrijsTotaal = eindprijs;
	setPersonPrijsTotal(personPrijsTotaal);

	console.log({ personPrijs });
	console.log({ personPrijsTotaal });
	console.log({ aanvraagPrijs });

	return { discountPrice, personPrijs, personPrijsTotaal, aanvraagPrijs };
}

function NroTotals({
	classes,
	personValues,
	discount,
	setDiscount,
	//  aanvraagPrijs,
	setAanvraagPrijs,
	//  personPrijsTotaal,
	setPersonPrijsTotal,
	pricelist,
	totalPersons,
	...otherProps
}) {
	const values = personValues;

	const { discountPrice, personPrijs, personPrijsTotaal, aanvraagPrijs } = useMemo(
		() => calculatePriceData(pricelist, discount, values, personValues, setPersonPrijsTotal, setAanvraagPrijs, totalPersons),
		[pricelist, discount, values, personValues, setPersonPrijsTotal, setAanvraagPrijs, totalPersons]
	);

	return (
		<Paper fullwidth="true" className={classes.paper}>
			<Grid item xs={12}>
				<Typography variant="h6" gutterBottom={true}>
					Bestelling
				</Typography>
			</Grid>
			<Grid container spacing={3}>
				<Grid item xs={12} sm={4}>
					<Typography variant="body1" align="left" gutterBottom={true}></Typography>
				</Grid>
				<Grid item xs={12} sm={4}>
					<Typography variant="body1" align="left" gutterBottom={true}>
						Per aanvraag
					</Typography>
				</Grid>
				<Grid item xs={12} sm={4}>
					<Typography variant="body1" align="left" gutterBottom={true}>
						Totaal
					</Typography>
				</Grid>
			</Grid>
			<Grid container spacing={3}>
				<Grid item xs={12} sm={4}>
					<Typography variant="body2" align="left" gutterBottom={true}>
						Aantal NRO-uittreksels: {calcActivePersons(totalPersons)}
					</Typography>
				</Grid>
				<Grid item xs={12} sm={4}>
					<Typography variant="body2" align="left" gutterBottom={true}>
						{new Intl.NumberFormat("nl-NL", { style: "currency", currency: "EUR" }).format(aanvraagPrijs)}
					</Typography>
				</Grid>
				<Grid item xs={12} sm={4}>
					<Typography variant="body2" align="left" gutterBottom={true}>
						{new Intl.NumberFormat("nl-NL", { style: "currency", currency: "EUR" }).format(personPrijs)}
					</Typography>
				</Grid>
			</Grid>
			<Grid container>
				<Grid item xs={12}>
					<Typography variant="h6" gutterBottom={true}>
						Totaal
					</Typography>
				</Grid>
			</Grid>
			<Grid container spacing={3}>
				<Grid item xs={12} sm={8}>
					<Typography variant="body2" align="left" gutterBottom={true}></Typography>
				</Grid>
				<Grid item xs={12} sm={4}>
					<Typography variant="body2" align="left" gutterBottom={true}>
						{new Intl.NumberFormat("nl-NL", { style: "currency", currency: "EUR" }).format(personPrijsTotaal)}
					</Typography>
				</Grid>
			</Grid>

			<Grid item xs={12}>
				<Divider />
			</Grid>
		</Paper>
	);
}

export default NroTotals;
